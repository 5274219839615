.Footer {
    position: relative;
    display: flex;
    z-index: 9998;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    height: 80px;
    transform: translateY(-100%);
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    visibility: visible;
}

.Footer p {
    margin: 0;
    font-size: 0.5vw;
}

.Footer_Info {
    z-index: 1;
    top: 0;
    align-items: center;
    margin-left: 24px;
    opacity: 1;
    width: 250px;
    max-height: 100px;
}

.Footer_Info p {
    margin-bottom: 4px;
    text-align: left;
}

.Footer_Contact {
    z-index: 1;
    width: 250px;
    text-align: center;
}

.Footer_SNS {
    z-index: 1;
    display: flex;
    height: 100%;
    width: 250px;

    justify-content: right;
    margin-right: 24px;
    line-height: 80px;
}

.Footer_Instagram {
    display: flex;
    justify-content: right;
}

.Footer_Instagram a {
    display: flex;
    color: white;
    text-decoration: none;
    align-items: center;
    font-size: 10px;
}

@media only screen and (max-width: 1024px) {
    .Footer {
        visibility: hidden;
    }
}