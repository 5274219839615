#Home {
    display: flex;
    height: 100%;
    z-index: 9999;
    align-content: center;
}

.Home_Backdrop {
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.2);
}

.Home_Logo{
    z-index: 500;
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 400px;
}


@media only screen and (max-width: 1024px) {
    .Home_Logo {
        max-height: 200px;
    }
}