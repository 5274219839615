.img-grid {
    display: grid;
    width: 80%;
    margin: auto;
    margin-bottom: 120px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}

.project {
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    position: relative;
}

.project img {
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    top: 0;
    left: 0;
}

.image-info {
    position: absolute;
    background-color: black;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
    color: white;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-info:hover {
    transition: opacity 0.4s;
    opacity: 0.7;
    cursor: pointer;
}

.image-info p {
    opacity: 1;
}

@media only screen and (max-width: 1024px) {
    .img-grid {
        grid-template-columns: 1fr;
    }

    .image-info {
        opacity: 0.7;
    }
}