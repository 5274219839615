#Contact {
    height:100%;
    align-content: center;
    justify-content: center;
    background-color: rgb(51, 51, 51);
}

.Contact_Content {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.Contact_Box {
    height: 100%;
    width: 100%;
    max-width: 500px;
    /* min-height: 200px; */
    max-height: 350px;
    margin: 0 50px;
}

.Contact_Map {
    height: 100%;
    width: 100%;
}

.Info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 50px;
    max-height: 600px;
}

.Info a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
}

.Info a p {
    margin-left: 5px;
}

@media only screen and (max-width: 720px) {
    .Contact_Content {
        flex-direction: column-reverse;
        justify-content: center;
        top: 60px;
        height: 100%;
        overflow: scroll;
    }

    .Contact_Box {
        max-height: 250px;
        margin: 0;
    }

    .Info {
        margin: 0;
        margin-bottom: 10px;
        max-height: 250px;
        font-size: 0.5rem;
        align-items: center;
        line-height: 10px;
    }

    .Info p {
        margin: 10px;
    }

}