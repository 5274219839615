.Modal-Backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
}

.Modal-Backdrop img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border:3px solid white;
}

.modalbutton {
    visibility: visible;
    color: white;
    position: absolute;
}

#modalleft {
    
    left: 0;
    margin-left: 100px;
}

#modalright {
    right: 0;
    margin-right: 100px;
}


@media only screen and (max-width: 1024px) {

    .modalbutton {
        visibility: hidden;
    }

    .Modal-Backdrop img {
        max-width: 90%;
    }
}