
.Header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  color: white;
}
  
.Header_Logo {
  position: relative;
  float: left;
  line-height: 60px;
  padding-left: 16px;
  margin-left: 24px;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 60px;
}

.Header_Links {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  padding-right: 16px;
  margin-right: 24px;

  list-style: none;
}

.Header_Link {
  text-decoration: none;
  color: white;
  line-height: 60px;
  font-size: 18px;
  padding: 0 16px;
}

.Header_Button {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  margin: auto;
  margin-right: 24px;
  align-self: center;
  background: transparent;
  border: none;
  outline: none;

}

@media only screen and (max-width: 1024px) {
  .Header_Button {
    visibility: visible;
  }

  .Header_Links {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    margin-top: 60px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    background-color: rgba(0, 0, 0, 1); 
    transform: translateX(100vw);
  }

  .Mobile_Nav {
    transform: none;
  }
  
}
  