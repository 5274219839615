.Project {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    align-content: center;
    overflow: scroll;
}

.Project_Title {
    margin: auto;
    margin-top: 60px;
    padding: 20px;
    font-size: 48px;
}