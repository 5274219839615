html, body {
  height: 100%;
}

#root {
  height: 100%;
}

#App {
  text-align: center;
  height: 100%;
}

.Main {
  height: 100%;
}